.modal {
    position: absolute;
    top: 0;
    left: 5%;
    height: auto;
    width: 350px;
    background: rgba(255, 255, 255, 0.90);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-top: 7px solid transparent;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }

  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .checkout-btn {

     background: green;
     color: white;
     border-radius: 5px;
     border: 1;
     box-shadow: 0px 2px 2px lightgray;
     opacity: 0.7;
  }
    .checkout-container{
      @apply absolute bottom-0 shadow  w-full p-10 bg-white;
    }
    .cart-container{
      @apply flex items-center flex-col h-4/5 overflow-y-auto overflow-x-hidden pt-5 pb-28;
    }
    .cart-count{
      @apply rounded-full h-5 w-5 flex items-center justify-center bg-red-500 text-white relative top-4 -left-1.5;
    }
    .modal{
      @apply w-screen lg:w-2/5 md:w-1/2;
    }
